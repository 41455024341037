$('.dropright-btn').on('click', e => {
    e.stopPropagation();
    e.preventDefault();
    const isDesktop = $(window).width() > 996;
    const position = isDesktop ? 'fixed' : 'inherit'

    if ($(e.currentTarget).hasClass('selected')) {
        $(e.currentTarget).removeClass('selected')
        $(e.currentTarget)
            .next('ul')
            .toggle();
    } else {
        $('.dropright-btn').removeClass('selected')
        $(e.currentTarget).addClass('selected')

        $('.dropright-menu:not(.selected)')
            .css('display', 'none')

        $(e.currentTarget)
            .next('ul')
            .css("position", position)
            .css("top", isDesktop ?  e.currentTarget.offsetParent.offsetTop - 8 : 0)
            .toggle();
    }
})