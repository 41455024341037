var searchIcon = document.getElementById("search-icon");
var searchInputContainer = document.getElementById("search-input")
var searchInput = document.getElementById("search-input-box");

if (searchIcon !== null) {
	document.getElementById("search-icon").addEventListener('click', event => {
		if (searchInputContainer.classList.contains("main-navigation-search--active")) {
			searchInputContainer.classList.remove("main-navigation-search--active");
			searchInput.blur();
			searchInput.value = '';
		} else {
			searchInputContainer.classList.add("main-navigation-search--active");
			searchInput.focus();
		}
	});
}

if (searchInput !== null) {
	searchInput.addEventListener("focus", (event) => {
		searchInputContainer.classList.add("main-navigation-search--active");
	})

	searchInput.addEventListener("input", (event) => {
		let searchString = event.srcElement.value;
		console.log(searchString);
		if (searchString.length > 3) {
			getResults(searchString);
		}
	})

	searchInput.addEventListener("keypress", (event) => {
		console.log(event);
		if (event.key === "Enter") {
			var viewAllButton = document.getElementById("ViewAllButton");
			var href = viewAllButton.getAttribute("href").split("?")[0];
			var encodedHref = href + "?q=" + encodeURI(searchInput.value);
			window.location = encodedHref;
		}
	})
}


function renderSearchResults(data) {
	document.getElementById("result-list")?.remove();
	if (data.d !== null && data.d.length > 0) {

		var viewAllButton = document.getElementById("ViewAllButton");
		var searchResultDiv = document.getElementById("search-result");

		var searchText = searchInput.value;
		var href = viewAllButton.getAttribute("href").split("?")[0];
		var encodedHref = href + "?q=" + encodeURI(searchText);
		viewAllButton.setAttribute("href", encodedHref)



		searchResultDiv.classList.remove("d-none");
		viewAllButton.classList.remove("d-none");

		let ul = document.createElement('ul');
		ul.classList.add("results");
		ul.id = "result-list";

		for (var i = 0; i < data.d.length; ++i) {
			var hit = data.d[i];
			var url = hit.Url;
			if (url.startsWith('://')) {
				url = url.replace('://', window.location.protocol + '//');
			}
			let a = document.createElement('a');
			a.href = url;
			a.text = hit.Title;
			let img = document.createElement('img');
			img.src = "/ui/assets/chevron-right-light.svg";
			img.classList.add("icon");
			a.appendChild(img);
			let li = document.createElement('li');
			li.appendChild(a);
			ul.appendChild(li);
		}

		searchResultDiv.insertBefore(ul, viewAllButton);
	}
};

function getResults(searchString) {
	let lang = document.getElementById("search-language").innerHTML;
	let url = "/" + lang + "/SearchService.asmx/GetSearchResult";
	let json = "{'query':'" + searchString + "'}";

	try {
		fetch(url, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8'
			},
			body: json
		}).then(response => response.json()
		).then((response) => {
			renderSearchResults(JSON.parse(JSON.stringify(response)))
		});
	} catch (error) {
		console.log(error);
	}
};

var knowledgeInputs = document.querySelectorAll('.js-knowledge-input')

knowledgeInputs.forEach((input) => {
	input.addEventListener('click', () => {
		console.log('click')
        window.location = input.value;
    });
})