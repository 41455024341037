const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var reCaptchaValid = false;

function verifyResponse(response) {
    let submitBtn = $('#submitButton');

    if (response) {
        reCaptchaValid = true;
        const email = $('#three').val();
        if (regex.test(email)) {
            submitBtn
                .removeClass('disabled')
                .prop('disabled', false)
        }
    } else {
        submitBtn
            .addClass('disabled')
            .prop('disabled', true)
    }
}

function onTokenExpire() {
    reCaptchaValid = false;
    $('#submitButton')
        .addClass('disabled')
        .prop('disabled', true)
}

$(document).ready(function () {
    let submitBtn = $('#submitButton');

    $('#three')
        .bind('keyup change', function () {
            const email = $(this).val();
            if (regex.test(email) && reCaptchaValid) {
                submitBtn
                    .removeClass('disabled')
                    .prop('disabled', false)
            }
            else {
                submitBtn
                    .addClass('disabled')
                    .prop('disabled', true)
            }
        })
});

