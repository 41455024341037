var cookieHelper = {
    getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1); 
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date(),
            expires = "";
        if (exdays !== 0) {
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            expires = "expires=" + d.toUTCString();
        }

        document.cookie = cname + "=" + cvalue + "; path=/;" + expires;
    }


};

var CookieBanner = {

    constants: {
        $CookieBanner: $(".CookieBanner")
    },

    onDismissButtonClick: function () {
        var $CookieBanner = CookieBanner.constants.$CookieBanner;
        
        $CookieBanner.on('closed.bs.alert', function () {
            cookieHelper.setCookie("cookiesAccepted", "1", 365 * 100);
        });
    },

    init: function () {
        CookieBanner.onDismissButtonClick();
    }

};

$(function () {
    CookieBanner.init();
});


