$(document).ready(function () {

    $('#searchInputBox').bind("onEnterPress", function (e) {
        e.preventDefault();

        const searchQuery = $('#searchInputBox').val();
        if (!searchQuery) {
            return false;
        }

        const url = `/search?query=${searchQuery}&page=1`
        window.location.replace(url);
    });
    $('#searchInputBox').keyup(function (e) {
        if (e.keyCode == 13) {
            $(this).trigger("onEnterPress");
        }
    });

});

function goToPage(query, page) {
    const searchUrl = window.location.href.split('?')[0]
    const url = `${searchUrl}?query=${query}&page=${page}`;

    window.location.replace(url) 
}