$('.thumbnail-container').click(e => {
    $('#ProductImage, .modal-image').attr('src', e.target.src)
});


$(document).ready(function () {
    hideEmptyCols();
    $('.product-card-wrapper a').click(function () {
        $('<div class=products-loading></div>').prependTo(document.body);
    });
});

function hideEmptyCols() {
    var columns = $(".table-striped > tbody > tr:first > td").length;
    for (var i = 0; i < columns; i++) {
        if ($(".table-striped > tbody > tr > td:nth-child(" + i + ")").filter(function () {
            return $(this).text() !== '';
        }).length === 0) {
            $(".table-striped > tbody > tr > td:nth-child(" + i + "), .table-striped > thead > tr > th:nth-child(" + i + ")").hide();
        }
    }
    for (var j = 0; j < columns; j++) {
        if ($(".table-striped > tbody > tr > td:nth-child(" + j + ")").filter(function () {
            return $(this).text() !== '0';
        }).length === 0) {
            $(".table-striped > tbody > tr > td:nth-child(" + j + "), .table-striped > thead > tr > th:nth-child(" + j + ")").hide();
        }
    }
}