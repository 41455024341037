function goToNewsPage(page) {
    var loc = window.location;
    const baseUrl = loc.origin + loc.pathname;
    const queryString = window.location.search;
    var params = new URLSearchParams(queryString);

    params.set('page', page);

    const url = baseUrl + '?' + params.toString();

    window.location.replace(url);
}