$(document).ready(() => {
    $("#airflows").click(function () {
        var ductdiameter = Math.round(((Math.sqrt(parseFloat($("#cfm").val()) / (Math.PI * parseFloat($("#velocity").val())))) * 24) * 100) / 100;
        var ductfriction = Math.round(((.109136 * Math.pow(parseFloat($("#cfm").val()), 1.9)) / Math.pow(ductdiameter, 5.02)) * 100) / 100;

        if (isNaN(ductdiameter) || isNaN(ductfriction)) {
            $("#diameter").text("Type in your CFM and Velocity");
            $("#friction").text("Type in your CFM and Velocity");
        }
        else {
            $("#diameter").val(ductdiameter + " in.");
            $("#friction").val(ductfriction + " in. water per 100'");
        }
    });

    $("#recs").click(function () {
        var roundDuct = Math.round((Math.sqrt((parseFloat($(".recW").val()) * parseFloat($(".recD").val())) / Math.PI) * 2));

        if (isNaN(roundDuct)) {
            $("#roundD").val("Type in both dimensions")
        }
        else { $("#roundD").val(roundDuct + " in."); }
    });

    $("#CFMbutton").click(function () {
        var CFMconvert = Math.round(parseFloat($(".InputCFM").val()) * 1.699);

        if (isNaN(CFMconvert)) {
            $("#cmh").val("Input your CFM");
        }
        else { $("#cmh").val(CFMconvert); }
    });

    $("#CMHbutton").click(function () {
        var CMHconvert = Math.round(parseFloat($(".InputCMH").val()) * 0.589);

        if (isNaN(CMHconvert)) {
            $("#cfmoutput").val("Input your m3/h");
        }

        else { $("#cfmoutput").val(CMHconvert); }
    });


});