const chooseRegion = (element) => {
    let regionCode = element.dataset.regionCode;
    let market = element.dataset.market;

    document.querySelectorAll(".region").forEach((regionElement) => {
        regionElement.classList.remove("active");
    });

    document.querySelector(".select-region").innerHTML = element.innerHTML;
    element.classList.toggle("active");
    document.querySelector(".confirm").setAttribute("data-url", "");
    document.querySelector(".confirm").setAttribute("data-market", market);
    document.querySelector(".confirm").setAttribute("data-region-code", regionCode);
    document.querySelector(".select-language").innerHTML = "---";
    document.querySelector(".confirm").disabled = true;

    if (regionCode !== undefined) {
        document.querySelectorAll(".language").forEach((languageElement) => {
            languageElement.classList.remove("active");
            languageElement.classList.remove("visible");
            if (languageElement.dataset.regionCode === regionCode) {
                languageElement.classList.remove("d-none");
                languageElement.classList.add("visible");
            } else {
                languageElement.classList.add("d-none");
            }
        });
        document.querySelector(".choose-region").classList.remove("open");
        if (document.querySelector(".language.visible") != null) {
            chooseLanguage(document.querySelector(".language.visible"));
        }
    } else {
        console.error("Region code:", regionCode);
        document.querySelectorAll(".language").forEach((languageElement) => {
            languageElement.classList.add("d-none");
        });
    }
};

const chooseLanguage = (element) => {
    let url = element.dataset.url;
    let lang = element.dataset.lang;
    let regionCode = element.dataset.regionCode;

    console.log("URL", url);
    document.querySelectorAll(".language").forEach((languageElement) => {
        languageElement.classList.remove("active");
    });

    document.querySelector(".select-language").innerHTML = element.innerHTML;
    element.classList.add("active");

    if (url !== undefined) {
        document.querySelector(".confirm").setAttribute("data-url", url);
        document.querySelector(".confirm").setAttribute("data-lang", lang);
        document.querySelector(".confirm").setAttribute("data-region-code", regionCode);
        document.querySelector(".choose-language").classList.remove("open");
        document.querySelector(".confirm").disabled = false;
    } else {
        console.error("Url:", url);
        document.querySelector(".confirm").setAttribute("data-url", "");
        document.querySelector(".confirm").setAttribute("data-lang", "");
        document.querySelector(".confirm").setAttribute("data-region-code", "");
        document.querySelector(".confirm").disabled = true;
    }
};

function setLanguageCookies(lang, market, region) {
    document.cookie = "language=" + lang + ";path=/";
    document.cookie = "nordfab#lang=" + lang + ";path=/";
    document.cookie = "market=" + market + ";path=/";
    document.cookie = "region=" + region + ";path=/";
}

if (document.querySelector(".choose-region-language")) {
    document
        .getElementById("dropdown-toggle")
        .addEventListener("click", (event) => {
            document.querySelector(".choose-region-language").classList.toggle("show");
            console.log(document.body);
            document.body.classList.toggle("open");
        });

    document.querySelectorAll(".select").forEach((element) =>
        element.addEventListener("click", (event) => {
            event.preventDefault();
            element.nextElementSibling.classList.toggle("open");
            element.classList.toggle("open");
            if (element.classList.contains("select-region")) {
                document.querySelector(".choose-language").classList.remove("open");
                document.querySelector(".select-language").classList.remove("open");
            } else {
                document.querySelector(".choose-region").classList.remove("open");
                document.querySelector(".select-region").classList.remove("open");
            }
        })
    );

    document.querySelectorAll(".region").forEach((element) => {
        element.addEventListener("click", (event) => {
            event.preventDefault();
            chooseRegion(element);
        });
    });

    document.querySelectorAll(".language").forEach((element) => {
        element.addEventListener("click", (event) => {
            event.preventDefault();
            chooseLanguage(element);
        });
    });

    document.querySelector(".confirm").addEventListener("click", (event) => {
        event.preventDefault();
        let url = document.querySelector(".confirm").dataset.url;
        let lang = document.querySelector(".confirm").dataset.lang;
        let market = document.querySelector(".confirm").dataset.market;
        let regionCode = document.querySelector(".confirm").dataset.regionCode;

        if (url !== undefined) {
            setLanguageCookies(lang, market, regionCode);
            window.location = url;
        } else {
            console.error("Url:", url);
        }
    });

    document.addEventListener(
        "click",
        (event) => {
            // If user either clicks X button OR clicks outside the modal window, then close modal by calling closeModal()
            if (
                !event.target.closest(".choose-region-language") &&
                !event.target.matches("#dropdown-toggle") &&
                document
                    .querySelector(".choose-region-language")
                    .classList.contains("show")
            ) {
                document.body.classList.remove("open");
                document
                    .querySelector(".choose-region-language")
                    .classList.remove("show");
                document.querySelectorAll(".select").forEach((element) => {
                    element.nextElementSibling.classList.remove("open");
                });
            }
        },
        false
    );
}