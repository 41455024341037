var map;

function buildContactUsMarkup(marker, translations) {
    return (
        `
    <div class="contact-us-infowindow">
        <div class="title">${marker.name}</div>
        <div class="address"><strong>${translations.Address}: </strong></div>
        <p>
            ${marker.address} </br>
            ${marker.postalCode} ${marker.city} </br>
            ${marker.country}
        </p>
        <div class="phone"><strong>${translations.Phone}: </strong><span>${marker.phone}</span></div>
        <div class="email"><strong>${translations.Email}: </strong> <span><a href="${marker.email}">${marker.email}</a></span></div>
    </div>
    `
    );
}

function initMap() {
    if (!document.getElementById('mapData')) return false;

    const mapData = JSON.parse(document.getElementById('mapData').value),
        { Latitude, Longitude, Zoom } = mapData.CountryGeoLocation,
        dealers = mapData.Dealers,
        translations = mapData.Translations,
        infowindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: parseFloat(Latitude), lng: parseFloat(Longitude) },
        zoom: parseInt(Zoom),
        maxZoom: 19,
        minZoom: 2
    });


    const officeIcon = document.getElementById("office-icon").value;
    const dealerIcon = document.getElementById("dealer-icon").value;

    const icons = {
        Office: {
            name: translations.Office,
            icon: officeIcon
        },
        Dealer: {
            name: translations.Dealer,
            icon: dealerIcon
        }
    };


    // Create markers.
    dealers.forEach(location => {
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.Latitude, location.Longitude),
            icon: icons[location.Type].icon,
            name: location.Title,
            email: location.Email,
            website: location.Website,
            address: location.Address,
            country: location.Country,
            postalCode: location.PostalCode,
            phone: location.Phone,
            city: location.City,
            map: map
        });

        //Add click listners on markers
        marker.addListener('click', function () {
            const contentWindow = buildContactUsMarkup(marker, translations);
            infowindow.setContent(contentWindow);
            infowindow.open(map, marker);
        })
    });


    let legend = document.getElementById('legend');
    for (var key in icons) {
        var type = icons[key];
        var name = type.name;
        var icon = type.icon;
        var div = document.createElement('div');
        div.className = "legend-row"
        div.innerHTML = '<img src="' + icon + '"> ' + name;
        legend.appendChild(div);
    }

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
}

